import notFound from "./../../assets/img/404.svg";

const NotFound = ({product}) => {
    return (
        <div className='notfound'>
            <img src={notFound} alt=""/>

        </div>
    )
}

export default NotFound
